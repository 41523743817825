import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, finalize, map, Subject, switchMap, takeUntil } from 'rxjs';
// services
import { CustomFieldService } from 'app/core/platform-data/custom-field.service';
// Types and enums
import { AffectedCategory } from 'app/shared/enums/affectedCategories';
import { Collection } from 'app/shared/enums/collection';
import { CustomFieldTypes } from 'app/shared/enums/customFieldTypes';
import { Case } from 'app/shared/types/case';
import { CustomField, ExtractionResult } from 'app/shared/types/customFieldTypes';
import { Company, Contact } from 'app/shared/types/entityTypes';
import { DataObserver } from 'app/shared/types/utilityTypes';

@Component({
	selector: 'custom-fields-details',
	templateUrl: './custom-fields-details.component.html',
	styleUrls: ['./custom-fields-details.component.scss'],
})
export class CustomFieldsDetailsComponent implements OnInit, OnDestroy {
	@Input() entity: Contact | Company;
	@Input() case: Case;
	private _unsubscribeAll: Subject<any> = new Subject<any>();
	private _customFieldsObserver: DataObserver;
	private _customFields: CustomField[];
	private _customFieldDataObserver: DataObserver;
	private _customFieldData: { field: CustomField; response: ExtractionResult }[] = [];
	private _extractionResults: ExtractionResult[];
	constructor(private _customFieldService: CustomFieldService) {}
	// -------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -------------------------------------------------------------------------

	ngOnInit() {
		if (!this.entity && !this.case) return;
		const type = this.entity ? (this.entity.type as any) : AffectedCategory.CASE;
		const parentCol = this.entity ? Collection.ENTITIES : Collection.CASES;
		const parentId = this.entity ? this.entity.id : this.case.id;

		this._customFieldService
			.readCustomFieldsObservable(type, true)
			.pipe(
				takeUntil(this._unsubscribeAll),
				finalize(() => {
					this._customFieldsObserver.unsubscribe();
				})
			)
			.subscribe((customFieldsObserver) => {
				this._customFieldsObserver = customFieldsObserver;
				if (!customFieldsObserver.data) return;
				this._customFields = customFieldsObserver.data;

				this._customFieldService
					.readCustomFieldDataObservable(parentCol, parentId)
					.pipe(
						takeUntil(this._unsubscribeAll),
						finalize(() => {
							this._customFieldDataObserver.unsubscribe();
						}),
						map((parentCollection) => {
							return parentCollection.data.map((parent) => {
								return this._customFieldService.readCustomFieldExtractionsObservable(parentCol, parentId, parent.id);
							});
						}),
						switchMap((observablesArray) => {
							return combineLatest(observablesArray);
						})
					)
					.subscribe(async (dataObsv: any[]) => {
						this._extractionResults = dataObsv
							.flatMap((observer) => {
								return observer.data;
							})
							.filter((res) => res.valid);

						this.customFields.forEach((field) => {
							const response = this._extractionResults.find((res) => res.parent === field.id);
							if (!response) return;
							this._customFieldData.push({
								field: field,
								response: response,
							});
						});
					});
			});

		return;
	}

	ngOnDestroy(): void {
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}
	// -------------------------------------------------------------------------
	// @ Public Methods
	// -------------------------------------------------------------------------

	/**
	 * Get ceil of of given number
	 * @param { number } num
	 * @return { number }
	 */
	ceilNumber(num: number): number {
		return Math.ceil(num);
	}

	// -------------------------------------------------------------------------
	// @ Accessors
	// -------------------------------------------------------------------------

	/**
	 * @type {typeof Entity}
	 */
	get customFields(): CustomField[] {
		return this._customFields;
	}

	/**
	 * Get custom field enum data
	 * @type { typeof CustomFieldTypes }
	 */
	get customFieldTypes(): typeof CustomFieldTypes {
		return CustomFieldTypes;
	}

	/**
	 * @type {boolean}
	 */
	get hasReponses(): boolean {
		return this._extractionResults.length > 0;
	}

	/**
	 *@type {{
	 * 		field: CustomField;
	 * 		response: ExtractionResult;
	 * 	}[]}
	 */
	get customFieldData(): {
		field: CustomField;
		response: ExtractionResult;
	}[] {
		return this._customFieldData;
	}
}
