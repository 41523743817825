import { Injectable, OnDestroy } from '@angular/core';
// types
import { AccountType } from 'app/shared/types/accountType';
import { RiskCriterion } from 'app/shared/types/riskCriterion';
// rxjs
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
// services
import { RiskEngineSettingsService } from 'app/core/platform-data/risk-engine-settings.service';
import { RiskCriterionType } from 'app/shared/enums/risk-criterion-type';
import { RiskEngineSettingsStatus } from 'app/shared/enums/risk-engine-setting-status';

@Injectable()
export class RiskCriteriaService implements OnDestroy {
	private _riskCriteria: BehaviorSubject<RiskCriterion[]> = new BehaviorSubject(null);
	private _accountTypes: BehaviorSubject<{ [key: string]: AccountType }> = new BehaviorSubject(null);
	private _unsubscribeAll: Subject<any> = new Subject<any>();
	private _riskSettingsStatus: RiskEngineSettingsStatus;

	constructor(private _riskEngineSettingsService: RiskEngineSettingsService) {
		this._riskEngineSettingsService.displayedRiskEngineSettings$.pipe(takeUntil(this._unsubscribeAll)).subscribe((riskEngineSettings) => {
			if (riskEngineSettings) {
				this._riskCriteria.next([...riskEngineSettings.riskCriteria]);

				this._accountTypes.next(riskEngineSettings.accountTypes);
				this._riskSettingsStatus = riskEngineSettings.status;
			}
		});
	}

	// -------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -------------------------------------------------------------------------
	ngOnDestroy(): void {
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}

	// -------------------------------------------------------------------------
	// @ Public Methods
	// -------------------------------------------------------------------------

	addRiskCriterion(riskCriterion: RiskCriterion) {
		const currCriteria = this._riskCriteria.value;

		currCriteria.push(riskCriterion);

		this._riskCriteria.next(currCriteria);
	}

	/**
	 * Update the risk criterion children list
	 * @param node
	 * @param accountTypeId
	 * @return { RiskCriterion }
	 */
	updateChildren(node: RiskCriterion, updatedAccountTypes: Array<string>): RiskCriterion {
		// Delete account type id which is deleted - from parent
		node.accountTypes = node.accountTypes.filter((nodeAccountTypeId) => updatedAccountTypes.includes(nodeAccountTypeId));

		// Check for children
		if (!node.children) return node;

		// Update node children list
		node.children = node.children.filter((nodeChildren) => {
			// Check for parent node account types does not consists of any child account types then it remove from child
			if (!node.accountTypes.some((accountType) => nodeChildren.accountTypes.includes(accountType))) return false;

			// Check for leaf node of the tree which consist of only one account type & empty children
			if (nodeChildren.accountTypes?.length === 1 && !nodeChildren.children?.length) {
				// if lead node account type id is similar to deleted accountTypeId then remove it from children list
				if (!node.accountTypes.includes(nodeChildren.accountTypes[0]))
					if (nodeChildren.type === RiskCriterionType.LISTENTRY) return false;
					else nodeChildren.accountTypes = [];
				return true;
			}
			// If nodeChildren is not an leaf node of the tree which has not exact 1 account type & have more than 0 children the recursively call updateChildren function
			return this.updateChildren(nodeChildren, node.accountTypes);
		});

		return node;
	}

	updateRiskCriterion(criterionId: string, riskCriterion: RiskCriterion): Promise<void> {
		return new Promise((resolve) => {
			const currCriteria = this._riskCriteria.value;

			let index = currCriteria.findIndex((criterion) => {
				return criterion.id === criterionId;
			});

			// Update risk criterion list
			riskCriterion = this.updateChildren(riskCriterion, riskCriterion.accountTypes);

			currCriteria[index] = {
				...currCriteria[index],
				...riskCriterion,
			};
			this._riskCriteria.next(currCriteria);
			resolve();
		});
	}

	deleteRiskCriterion(criterionId: number): Promise<void> {
		return new Promise((resolve, reject) => {
			const currCriteria = this._riskCriteria.value;

			if (currCriteria[criterionId]) {
				currCriteria.splice(criterionId, 1);
				this._riskCriteria.next(currCriteria);
				resolve();
			} else reject(new Error('Setting not found. Contact Support!'));
		});
	}

	saveChanges(): Promise<void> {
		return this._riskEngineSettingsService.updateSetting({
			riskCriteria: this._riskCriteria.value,
		});
	}

	// -------------------------------------------------------------------------
	// @ Accessors
	// -------------------------------------------------------------------------

	get riskCriteria$(): Observable<RiskCriterion[]> {
		return this._riskCriteria.asObservable();
	}

	get accountTypes$(): Observable<{ [key: string]: AccountType }> {
		return this._accountTypes.asObservable();
	}

	get riskSettingsStatus(): RiskEngineSettingsStatus {
		return this._riskSettingsStatus;
	}
}
