import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, finalize, Subject, takeUntil } from 'rxjs';
// services
import { EntityHelperService } from 'app/core/entities/entity-helper.service';
// types and enums
import { Company, Contact } from 'app/shared/types/entityTypes';
import { NegativeNewsResults } from 'app/shared/types/negativeNewsResults';
import { BaseRunningProcessesEntity, isBaseRunningProcessEntity } from 'app/shared/types/runningProcessesEntity';
import { DataObserver } from 'app/shared/types/utilityTypes';

@Component({
	selector: 'report-news-check-list',
	templateUrl: './news-check-list.component.html',
	styleUrls: ['./news-check-list.component.scss'],
})
export class NewsCheckListComponent implements OnInit, OnDestroy {
	@Input() entity: Contact | Company;

	private _newsCheckDataObservers: DataObserver[] = [];
	private _unsubscribeAll: Subject<any> = new Subject<any>();
	private _allCheckResults: NegativeNewsResults[] = [];

	columnsToDisplay = ['date', 'title', 'status', 'avgScore'];
	constructor(private _entityHelperService: EntityHelperService) {}
	// -------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -------------------------------------------------------------------------

	ngOnInit(): void {
		this._loadNewsCheckResults();
	}

	ngOnDestroy(): void {
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}

	// -------------------------------------------------------------------------
	// @ Private methods
	// -------------------------------------------------------------------------

	/**
	 * Initializes the news check results
	 * @return {Promise<void>}
	 */
	private async _loadNewsCheckResults(): Promise<void> {
		if (this._newsCheckDataObservers) this._newsCheckDataObservers.forEach((ncDataObs) => ncDataObs.unsubscribe());
		const resArr: NegativeNewsResults[] = [];
		const { dataObs, relatedEntities } = await this._entityHelperService.loadNewsCheckResults(this.entity.id, false);

		combineLatest(dataObs)
			.pipe(
				takeUntil(this._unsubscribeAll),
				finalize(() => this._newsCheckDataObservers.forEach((ncDataObs) => ncDataObs.unsubscribe()))
			)
			.subscribe((newsCheckDataObs) => {
				this._newsCheckDataObservers = newsCheckDataObs;
				newsCheckDataObs.forEach((slcData) => resArr.push(...slcData.data));
				resArr.forEach((resData) => {
					if (isBaseRunningProcessEntity(resData)) {
						const tempProcessData = { ...resData } as BaseRunningProcessesEntity;
						resData.title = tempProcessData.entityName;
						resData.date = tempProcessData.createdAt;
						resData.type = tempProcessData.type;
						resData.subType = tempProcessData.subType;
						return;
					}
					if (resData.parentId === null) return;
					if (resData.parentId === this.entity.id) resData.title = this.entity.displayName;
					if (!relatedEntities || relatedEntities.length === 0) return;
					const affEntity: Contact | Company = relatedEntities.find((entity) => entity.id === resData.parentId);
					if (!affEntity) return;
					resData.title = affEntity.displayName;
				});
				this._allCheckResults = resArr
					.filter((el, i, a) => i === a.indexOf(el))
					.sort((a, b) => Number(b.date) - Number(a.date))
					.slice(0, 6);
			});
	}

	// -------------------------------------------------------------------------
	// @ Accessors
	// -------------------------------------------------------------------------
	/**
	 * @type {NegativeNewsResults[]}
	 */
	get dataSource(): NegativeNewsResults[] {
		return this._allCheckResults;
	}
}
