// Angular
import { Injectable } from '@angular/core';
import { Functions, httpsCallable } from '@angular/fire/functions';
// Services
import { AuthService } from '../auth/auth.service';
import { DataService } from '../database/data.service';
import { GraphdbService } from '../database/graphdb/graphdb.service';
// Types
import { DocumentReference } from '@angular/fire/firestore';
import { Collection } from 'app/shared/enums/collection';
import { Entity } from 'app/shared/enums/entity';
import { GraphReadType } from 'app/shared/types/database/graphRequest';
import { EntityRelation } from 'app/shared/types/entity-relation';
import { Company, Contact } from 'app/shared/types/entityTypes';
import { QueryCondition } from 'app/shared/types/query-condition';
import { RelationshipType } from 'app/shared/types/relationshipType';
import { DataObserver } from 'app/shared/types/utilityTypes';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class EntityHelperService {
	private _entityPath: string;
	private _relTypePath: string;
	private _accountPath: string;
	private _entityRelationPath: string;

	constructor(
		private _functions: Functions,
		private _authService: AuthService,
		private _dataService: DataService,
		private _graphdbService: GraphdbService
	) {
		this._functions.region = 'europe-west1';
		this._accountPath = `${Collection.ACCOUNTS}/${this._authService.accountId}`;
		this._entityPath = `${this._accountPath}/${Collection.ENTITIES}`;
		this._relTypePath = `${this._accountPath}/${Collection.RELATIONSHIPTYPES}`;
		this._entityRelationPath = `${this._accountPath}/${Collection.ENTITYRELATIONS}`;
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Entity methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Creates a new entity
	 * @param {Company | Contact} entityData
	 * @return {Promise<Company | Contact>}
	 */
	async createEntity(entityData: Company | Contact): Promise<Company | Contact> {
		entityData.created = new Date();
		entityData.createdBy = this._authService.userId;
		entityData.parent = this._authService.accountId;
		entityData.owner = this._authService.userId;

		const ret = await this._dataService.storeDocument({ ...entityData }, this._entityPath);
		if (!ret) throw new Error('Error while creating entity');

		// // create graphdb node
		// this._graphdbService
		// 	.gCreate([{ [GraphRecordType.NODE]: { id: ret.id, type: GraphDataType.ENTITY, meta: JSON.stringify(entityData) } }])
		// 	.catch((error) => {
		// 		console.error(error);
		// 	});

		entityData.id = ret.id;
		return entityData;
	}

	/**
	 * Checks if the entity exists
	 * @param {string} entityId
	 * @return {Promise<boolean>}
	 */
	async entityExists(entityId: string): Promise<boolean> {
		return await this._dataService.documentExists(this._entityPath, entityId);
	}

	/**
	 * Returns an observable to load all entities of the account
	 * @return {Observable<DataObserver>}
	 */
	readEntities(): Observable<DataObserver> {
		return this._dataService.loadAllEntriesObservable(this._entityPath);
	}

	/**
	 * Returns a observable to load an entity
	 * @param {string} entityId
	 * @return {Observable<DataObserver>}
	 */
	readEntity(entityId: string): Observable<DataObserver> {
		return this._dataService.loadDataObservable(this._entityPath, entityId);
	}

	/**
	 * Returns a promise to load an entity
	 * @param {string} entityId
	 * @return {Promise<Company | Contact>}
	 */
	readEntityData(entityId: string): Promise<Company | Contact> {
		return this._dataService.loadData(this._entityPath, entityId);
	}

	/**
	 * Updates the entity
	 * @param {(Contact | Company)} entity
	 * @param {string} entityId
	 * @return {Promise<void>}
	 */
	async updateEntity(entity: Partial<Contact | Company>, entityId: string): Promise<void> {
		await this._dataService.updateDocument({ ...entity }, this._entityPath, entityId);
		// await this._graphdbService
		// 	.gUpdate([{ [GraphRecordType.NODE]: { id: entityId, prop: 'meta', value: JSON.stringify(entity) } }])
		// 	.catch((error) => {
		// 		console.error(error);
		// 	});

		return;
	}

	/**
	 * Search records and get the results as promise
	 * @param {QueryCondition[]} condition condition for query
	 * @return {Promise}
	 */
	searchEntities(condition: QueryCondition[]): Promise<any[]> {
		return this._dataService.queryData(this._entityPath, condition);
	}

	/**
	 * Search records from entity relations and get the results as promise
	 * @param { QueryCondition[] } condition condition for query
	 * @return { Promise }
	 */
	searchEntityRelations(condition: QueryCondition[]): Promise<any[]> {
		return this._dataService.queryData(this._entityRelationPath, condition);
	}

	/**
	 * Seach records and get the results as observable
	 * @param {QueryCondition[]} condition condition for query
	 * @return { Observable }
	 */
	searchEntitiesObserable(condition: QueryCondition[]): Observable<DataObserver> {
		return this._dataService.queryDataObservable(this._entityPath, condition);
	}

	/**
	 * Gets observers for connected data (files,cases,relations) to check if entity is deletable
	 * @param {string} entityId
	 * @return {Observable<DataObserver>[]}
	 */
	checkEmptyObservables(entityId: string): Observable<DataObserver>[] {
		const entityRef = this.getEntityReference(entityId);
		const relationsPath = `${this._accountPath}/${Collection.ENTITYRELATIONS}`;
		const observableArr = [
			this._dataService.loadAllEntriesObservable(`${this._accountPath}/${Collection.ENTITIES}/${entityId}/${Collection.FILES}`),
			this._dataService.queryDataObservable(`${this._accountPath}/${Collection.CASES}`, [
				{ field: 'linkedRecord', operator: '==', value: entityId },
			]),
			this._dataService.queryDataObservable(relationsPath, [{ field: 'child', operator: '==', value: entityRef }]),
			this._dataService.queryDataObservable(relationsPath, [{ field: 'parent', operator: '==', value: entityRef }]),
			this._dataService.queryDataObservable(`${this._accountPath}/${Collection.ENTITIES}`, [
				{ field: 'company', operator: '==', value: entityId },
			]),
		];
		return observableArr;
	}

	/**
	 * Check for content type used in file
	 * @param { string } definitionTypeId
	 * @return { Promise<boolean> }
	 */
	async checkForContentTypeUsedForFile(definitionTypeId: string): Promise<boolean> {
		// Get all entity list
		const entityList = await this._dataService.loadAllEntries(this._entityPath);

		for (const entityData of entityList.docs) {
			// Get files again entity
			const fileList = await this._dataService.loadAllEntries(
				`${this._accountPath}/${Collection.ENTITIES}/${entityData.id}/${Collection.FILES}`
			);

			// Check for definition type exist in file
			const foundFile = fileList.docs.find((data) => data.data().contents.includes(definitionTypeId));

			if (foundFile) return true; // Exit the loop and return true if a file with the specified content type is found
		}

		return false;
	}

	/**
	 * Get entity reference
	 * @param {string} entityId
	 * @return {DocumentReference}
	 */
	getEntityReference(entityId: string): DocumentReference {
		return this._dataService.getFirestoreRef(this._entityPath, entityId);
	}

	/**
	 * Get entity collection path
	 * @return {string}
	 */
	getEntityCollectionPath(): string {
		return this._entityPath;
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Entity relations methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 *
	 * @param {string} childId
	 * @param {string} childEntityName
	 * @param {string} parentId
	 * @param {string} parentEntityName
	 * @param {string} relTypeId
	 * @param {string} relTitle
	 * @param {Entity} childEntityType
	 * @param {Entity} parentEntityType
	 * @return {Promise<EntityRelation>}
	 */
	async createEntityRelation(
		childId: string,
		childEntityName: string,
		parentId: string,
		parentEntityName: string,
		relTypeId: string,
		relTitle: string,
		childEntityType: Entity,
		parentEntityType: Entity
	): Promise<EntityRelation> {
		const newRelation: EntityRelation = {
			owner: this._authService.accountId,
			parent: this._dataService.getFirestoreRef(this._entityPath, parentId),
			parentId: parentId,
			child: this._dataService.getFirestoreRef(this._entityPath, childId),
			childId: childId,
			type: relTypeId,
			parentEntityName: parentEntityName,
			childEntityName: childEntityName,
			created: new Date(),
			createdBy: this._authService.userId,
			childEntityType: childEntityType,
			parentEntityType: parentEntityType,
		};

		const ret = await this._dataService.storeDocument(newRelation, `${this._accountPath}/${Collection.ENTITYRELATIONS}`);
		if (!ret) return;
		// await this._graphdbService
		// 	.gCreate([
		// 		{
		// 			[GraphRecordType.RELATION]: {
		// 				child_id: childId,
		// 				parent_id: parentId,
		// 				id: relTypeId,
		// 				meta: JSON.stringify({ title: relTitle, relId: relTypeId }),
		// 			},
		// 		},
		// 	])
		// 	.catch((err) => {
		// 		console.error(err);
		// 	});
		return { ...newRelation, id: ret.id };
	}

	/**
	 * Get all relationship types for logged in user's account
	 * @return {Promise<RelationshipType[]>}
	 */
	async getRelationTypes(): Promise<RelationshipType[]> {
		const snap = await this._dataService.loadAllEntries(this._relTypePath);
		if (snap.empty) return [];
		return snap.docs.map((m) => {
			return { ...m.data(), id: m.id } as RelationshipType;
		});
	}

	/**
	 * Get all relationship types for logged in user's account
	 * @return {Promise<EntityRelation[]>}
	 */
	private async _getRelations(): Promise<EntityRelation[]> {
		const snap = await this._dataService.loadAllEntries(`${this._accountPath}/${Collection.ENTITYRELATIONS}`);
		if (snap.empty) return [];
		return snap.docs.map((m) => {
			return { ...m.data(), id: m.id } as EntityRelation;
		});
	}

	/**
	 * Deletes an entity relation
	 *
	 * @param {EntityRelation} relation
	 * @return {Promise<void>}
	 */
	async deleteRelation(relation: EntityRelation): Promise<void> {
		// await this._graphdbService
		// 	.gDelete([{ [GraphRecordType.RELATION]: { id: relation.type, child_id: relation.childId, parent_id: relation.parentId } }])
		// 	.catch((err) => {
		// 		console.error(err);
		// 	});

		await this._dataService.deleteData(`${this._accountPath}/${Collection.ENTITYRELATIONS}`, relation.id);

		return;
	}

	/**
	 * Loads all Contacts for an entity of type Company
	 * @param {string} entityId
	 * @return {Observable<DataObserver>}
	 */
	getRelatedContacts(entityId: string): Observable<DataObserver> {
		return this._dataService.queryDataObservable(this._entityPath, [{ field: 'company', operator: '==', value: entityId }]);
	}

	/**
	 * @deprecated Will be removed in future
	 * @param {string} entityId
	 * @return {Promise<EntityRelation[]>}
	 */
	async getConnectedRelations(entityId: string): Promise<EntityRelation[]> {
		const getConnectedRelations = httpsCallable(this._functions, 'getConnectedRelations');
		const res = await getConnectedRelations({ entityId: entityId });
		return JSON.parse(res.data as string) as EntityRelation[];
	}

	/**
	 * Queries the graphdb for entity relations across multiple hops and returns them as EntityRelation objects
	 * @param {string} entityId
	 * @return {Promise<EntityRelation[]>}
	 */
	async getEntityRelationsGraphDB(entityId: string): Promise<EntityRelation[]> {
		const query = [{ [GraphReadType.SUBGRAPH]: { id: entityId, n_hops: 5 } }];
		const gdpResp = null; // (await this._graphdbService.gRead(query)).query_payload;
		const relations: { source: string; destination: string; type: string }[] = [];
		const newRelations: EntityRelation[] = [];
		const entityRelationsFirestore = await this._getRelations();

		if (!gdpResp) return newRelations; // Added temp. for the change gdpResp = null
		// Find sample requests here: https://github.com/synapzegmbh/AxonQueryGen/blob/main/examples.md
		const gdpRespData = gdpResp[0].result;
		gdpRespData.forEach((resp) => {
			// lookup index in columns array for nodes and relationships
			let nodeIndex = resp.columns.findIndex((m) => m === 'nodes');
			let edgeIndex = resp.columns.findIndex((m) => m === 'relationships');
			let entities: (Contact | Company)[] = [];

			resp.data.forEach((data) => {
				// data object contains meta and row arrays, extract by index from columns array
				let nodeMetaData = data.meta[nodeIndex];
				let nodeRowData = data.row[nodeIndex];
				let edgeMetaData = data.meta[edgeIndex];
				let edgeRowData = data.row[edgeIndex];

				// Parse entities
				nodeRowData.forEach((node, idx) => {
					if (!node['module.meta']) return;
					// node at property module.meta contains the actual entity data
					let entity: Contact | Company = { ...JSON.parse(node['module.meta']), id: nodeMetaData[idx].id };
					entities.push(entity);
				});

				// Parse relations
				edgeMetaData.forEach((meta, idx) => {
					// edge meta must contain an id object with src and dst properties to identify the relation
					if (!meta || typeof meta.id === 'string') return;
					relations.push({
						source: meta.id.src,
						destination: meta.id.dst,
						// edgeRowData at property meta contains the actual relation data
						type: JSON.parse(edgeRowData[idx].meta).relId,
					});
				});
			});
			// Create EntityRelation objects from relations array
			relations.forEach((relation) => {
				let parent = entities.find((m) => m.id === relation.source);
				let child = entities.find((m) => m.id === relation.destination);
				let relationObj = entityRelationsFirestore.find(
					(r) => r.type === relation.type && r.childId === relation.destination && r.parentId === relation.source
				);
				if (!parent || !child) return;
				newRelations.push({
					owner: this._authService.accountId,
					parent: this._dataService.getFirestoreRef(this._entityPath, parent.id),
					parentId: parent.id,
					child: this._dataService.getFirestoreRef(this._entityPath, child.id),
					childId: child.id,
					created: new Date(),
					createdBy: this._authService.userId,
					childEntityName: child.displayName,
					parentEntityName: parent.displayName,
					type: relation.type,
					parentEntityType: parent.type,
					childEntityType: child.type,
					id: relationObj ? relationObj.id : null,
				});
			});
		});
		return newRelations;
	}

	/**
	 * Queries the graphdb for entity relations across multiple hops and returns the entities included in the relations
	 * @param {string} entityId
	 * @return {Promise<(Contact | Company)[]>}
	 */
	async getRelatedEntities(entityId: string, slc?: boolean): Promise<(Contact | Company)[]> {
		const query = [{ [GraphReadType.SUBGRAPH]: { id: entityId, n_hops: 5 } }];
		const entities: (Contact | Company)[] = [];
		let gdpResp;
		// try {
		// 	gdpResp = (await this._graphdbService.gRead(query)).query_payload;
		// } catch (error) {
		// 	console.error(error);
		// 	return entities;
		// }
		const relations: { source: string; destination: string; type: string }[] = [];
		let relationshipTypes: RelationshipType[];
		if (slc) relationshipTypes = await (await this.getRelationTypes()).filter((r) => r.slcRelevant);

		if (!gdpResp) return entities; // Added temp. for the change commented code for load gdpResp
		// Find sample requests here: https://github.com/synapzegmbh/AxonQueryGen/blob/main/examples.md
		const gdpRespData = gdpResp[0].result;
		gdpRespData.forEach((resp) => {
			// lookup index in columns array for nodes and relationships
			const nodeIndex = resp.columns.findIndex((m) => m === 'nodes');
			const edgeIndex = resp.columns.findIndex((m) => m === 'relationships');

			resp.data.forEach((data) => {
				// data object contains meta and row arrays, extract by index from columns array
				const nodeMetaData = data.meta[nodeIndex];
				const nodeRowData = data.row[nodeIndex];
				const edgeMetaData = data.meta[edgeIndex];
				const edgeRowData = data.row[edgeIndex];

				// Parse entities
				nodeRowData.forEach((node, idx) => {
					if (!node['module.meta']) return;
					// node at property module.meta contains the actual entity data
					let entity: Contact | Company = { ...JSON.parse(node['module.meta']), id: nodeMetaData[idx].id };
					entities.push(entity);
				});
				// Only check for relationtypes if slc relevant
				if (!slc) return;
				// Parse relations
				edgeMetaData.forEach((meta, idx) => {
					// edge meta must contain an id object with src and dst properties to identify the relation
					if (!meta || typeof meta.id === 'string') return;
					// edgeRowData at property meta contains the actual relation data
					const id = JSON.parse(edgeRowData[idx].meta).relId;
					// Check if type of relation is slc relevant
					const isSlcRelevant = relationshipTypes.some((relationType) => relationType.id === id);
					// only push relations that have a relationshiptype that is slc relevant
					if (isSlcRelevant)
						relations.push({
							source: meta.id.src,
							destination: meta.id.dst,
							type: id,
						});
				});
			});
		});
		// filter entities that are not in a slc relevant relation
		if (slc) return entities.filter((entity) => relations.some((rel) => rel.destination === entity.id || rel.source === entity.id));

		return entities;
	}

	// -----------------------------------------------------------------------------------------------------
	// @ SanctionListCheck and Newscheck methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Starts a sanctions list check for the specified entity
	 * @param {string} entityId
	 * @return {Promise<void>}
	 */
	async initSanctionListCheck(entityId: string): Promise<void> {
		const sanctionsListCheck = httpsCallable(this._functions, 'sanctionsListCheck');
		await sanctionsListCheck({ entityIds: [entityId] });
		return;
	}

	/**
	 * Starts a sanctions list check for the specified entity and its related entities
	 * @param {string} entityId
	 * @return {Promise<void>}
	 */
	async initSanctionListCheckRelated(entityId: string): Promise<void> {
		const relatedEntities = await this.getRelatedEntities(entityId, true);
		const entityIds = relatedEntities.map((relatedEntity) => relatedEntity.id);
		const sanctionsListCheck = httpsCallable(this._functions, 'sanctionsListCheck');
		await sanctionsListCheck({ entityIds: entityIds });
		return;
	}

	/**
	 * Load Sanction list check results and ongoing checks and return observable
	 * @param {string} entityId The entitie's id
	 * @return {Promise<{dataObs: Observable<DataObserver[]>, relatedEntities?: (Contact | Company)[]}>}
	 */
	async loadSanctionListCheckResults(
		entityId: string,
		showRelated: boolean
	): Promise<{ dataObs: Observable<DataObserver>[]; relatedEntities?: (Contact | Company)[] }> {
		const dataObservers: Observable<DataObserver>[] = [];
		const retObj: { dataObs: Observable<DataObserver>[]; relatedEntities?: (Contact | Company)[] } = {
			dataObs: null,
		};
		dataObservers.push(
			// Get Ongoing SLC check for entity
			this._dataService.queryDataObservable(`${this._accountPath}/${Collection.RUNNINGPROCESSES}`, [
				{ field: 'entity', operator: '==', value: this.getEntityReference(entityId) },
				{ field: 'type', operator: '==', value: 'OFAC' },
			]),
			// Get SLC results for actual entity
			this._dataService.loadAllEntriesObservable(`${this._entityPath}/${entityId}/${Collection.SANCTIONLISTRESULTS}`, true)
		);

		if (showRelated) {
			const relatedEntities = await this.getRelatedEntities(entityId, true);
			if (relatedEntities.length) {
				retObj.relatedEntities = relatedEntities;
				relatedEntities.forEach((entity) => {
					if (entity.id === entityId) return;
					const pathRelated = `${this._entityPath}/${entity.id}/${Collection.SANCTIONLISTRESULTS}`;
					dataObservers.push(this._dataService.loadAllEntriesObservable(pathRelated, true));
				});
			}
		}

		retObj.dataObs = dataObservers;
		return retObj;
	}

	/**
	 * Load News check results and ongoing checks and return observable
	 * @param {string} entityId The entitie's id
	 * @return {Promise<{dataObs: Observable<DataObserver[]>, relatedEntities?: (Contact | Company)[]}>}
	 */
	async loadNewsCheckResults(
		entityId: string,
		showRelated: boolean
	): Promise<{ dataObs: Observable<DataObserver>[]; relatedEntities?: (Contact | Company)[] }> {
		const dataObservers: Observable<DataObserver>[] = [];
		const retObj: { dataObs: Observable<DataObserver>[]; relatedEntities?: (Contact | Company)[] } = {
			dataObs: null,
		};

		// Get Ongoing newscheck for entity
		dataObservers.push(
			this._dataService.queryDataObservable(`${this._accountPath}/${Collection.RUNNINGPROCESSES}`, [
				{ field: 'entity', operator: '==', value: this.getEntityReference(entityId) },
				{ field: 'type', operator: '==', value: 'NEWSCHECK' },
			]),
			// get existing newscheck results
			this._dataService.loadAllEntriesObservable(`${this._entityPath}/${entityId}/${Collection.NEWSCHECKRESULT}`, true)
		);

		if (showRelated) {
			const relatedEntities = await this.getRelatedEntities(entityId, true);
			if (relatedEntities.length) {
				retObj.relatedEntities = relatedEntities;
				relatedEntities.forEach((entity) => {
					if (entity.id === entityId) return;
					const pathRelated = `${this._entityPath}/${entity.id}/${Collection.NEWSCHECKRESULT}`;
					dataObservers.push(this._dataService.loadAllEntriesObservable(pathRelated, true));
				});
			}
		}

		retObj.dataObs = dataObservers;
		return retObj;
	}

	/**
	 *
	 * @param {string} entityId
	 * @return {Promise<void>}
	 */
	async initNewsCheck(entityId: string): Promise<void> {
		const newsCheck = httpsCallable(this._functions, 'newsCheck');
		await newsCheck({ entityIds: [entityId] });
		return;
	}

	/**
	 *
	 * @param {string} entityId
	 * @return {Promise<void>}
	 */
	async initNewsCheckRelated(entityId: string): Promise<void> {
		const relatedEntities = await this.getRelatedEntities(entityId, true);
		const entityIds = relatedEntities.map((relatedEntity) => relatedEntity.id);
		const newsCheck = httpsCallable(this._functions, 'newsCheck');
		await newsCheck({ entityIds: entityIds });
		return;
	}
}
