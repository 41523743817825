import { Injectable, OnDestroy } from '@angular/core';
import { Functions } from '@angular/fire/functions';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

// types
import { Alert } from 'app/shared/types/alert';
import { Approval } from 'app/shared/types/approval';
import { Case } from 'app/shared/types/case';
import { Company, Contact, ICompany, IContact } from 'app/shared/types/entityTypes';
import { FileData, FileMap } from 'app/shared/types/fileData';
import { SanctionsListCheck } from 'app/shared/types/sanctionsListCheck';
import { Task } from 'app/shared/types/taskboardTypes';
import { DataObserver } from 'app/shared/types/utilityTypes';

// rxjs
import { BehaviorSubject, combineLatest, finalize, lastValueFrom, Observable, Subject, takeUntil } from 'rxjs';
// services
import { EntityHelperService } from 'app/core/entities/entity-helper.service';
import { ApprovalService } from 'app/core/platform-data/approvals.service';
import { StorageService } from 'app/core/storage/storage.service';

// enums
import { CaseStage } from 'app/shared/enums/case-stage';
import { Collection } from 'app/shared/enums/collection';

// components
import { DocumentReference } from '@angular/fire/firestore';
import { CaseService } from 'app/core/platform-data/case.service';
import { DeclineDialogComponent } from 'app/shared/components/approval/decline-dialog/decline-dialog.component';

@Injectable()
export class CaseViewService implements OnDestroy {
	private _case: BehaviorSubject<Case> = new BehaviorSubject<Case>(null);
	private _linkedEntity: BehaviorSubject<Contact | Company> = new BehaviorSubject(null);
	private _sanctionListResults: BehaviorSubject<SanctionsListCheck[]> = new BehaviorSubject(null);
	private _alerts: BehaviorSubject<Alert[]> = new BehaviorSubject<Alert[]>([]);
	private _unsubscribeAll: Subject<any> = new Subject<any>();
	private _tasks: BehaviorSubject<Task[]> = new BehaviorSubject([]);
	private _files: BehaviorSubject<FileData[]> = new BehaviorSubject(null);
	private _fileMap: BehaviorSubject<FileMap> = new BehaviorSubject<FileMap>({});
	private _relatedEntities: BehaviorSubject<(IContact | ICompany)[]> = new BehaviorSubject<(IContact | ICompany)[]>(null);
	private _approval: BehaviorSubject<Approval> = new BehaviorSubject<Approval>(null);
	// Observers to unsubscribe
	private _caseObserver: DataObserver;
	private _entityObserver: DataObserver;
	private _taskObserver: DataObserver;
	private _fileObservable: DataObserver;
	private _approvalApproverObserver: DataObserver;
	private _approvalOwnerObserver: DataObserver;

	constructor(
		private _storageService: StorageService,
		private _activatedRoute: ActivatedRoute,
		private _entityHelperService: EntityHelperService,
		private _approvalService: ApprovalService,
		private _dialog: MatDialog,
		private _functions: Functions,
		private _caseService: CaseService
	) {
		this._functions.region = 'europe-west1';
		this._activatedRoute.params.pipe(takeUntil(this._unsubscribeAll)).subscribe((params) => {
			if (!params.id) return;
			this._caseService
				.readCase(params.id)
				.pipe(
					takeUntil(this._unsubscribeAll),
					finalize(() => {
						this._caseObserver.unsubscribe();
					})
				)
				.subscribe(async (caseObserver) => {
					this._caseObserver = caseObserver;
					const caseData = caseObserver.data;
					this._case.next(caseData);
					// init data
					this._initApproval();
					this._initEntity();
					this._initTasks();
					this._initFiles();
				});
		});
	}

	// -------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -------------------------------------------------------------------------

	ngOnDestroy(): void {
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}

	// -------------------------------------------------------------------------
	// @ Public methods
	// -------------------------------------------------------------------------
	/**
	 * Updates the case
	 *
	 * @param {Partial<Case>} updateData
	 * @return {Promise<void>}
	 */
	updateCase(updateData: Partial<Case>): Promise<void> {
		return this._caseService.updateCase(this.case.id, updateData);
	}

	/**
	 * Uploads file to storage and links it to case
	 *
	 * @param {FileList} files
	 * @return {Promise<void>}
	 */
	uploadFiles(files: FileList): Promise<void> {
		const docRef = this._caseService.getCaseReference(this.case.id);
		return this._storageService.uploadFiles(files, docRef);
	}

	/**
	 * Creates a approval for case
	 *
	 * @return {Promise<void>}
	 */
	setForApproval(): Promise<void> {
		const docRef = this._caseService.getCaseReference(this.case.id);
		return this._approvalService.submittingForApproval(docRef, this.case.title);
	}

	/**
	 * Approves the case/approval
	 *
	 * @return {Promise<void>}
	 */
	approveCase(): Promise<void> {
		return this._approvalService.approve(this._approval.value);
	}

	/**
	 * Declines the case/approval
	 *
	 * @return {Promise<void>}
	 */
	async declineCase(): Promise<void> {
		const dialogRef = this._dialog.open(DeclineDialogComponent);

		const result = await lastValueFrom(dialogRef.afterClosed());
		if (!result) return;

		return this._approvalService.decline(this._approval.value, result);
	}

	/**
	 * Reloads related entities
	 *
	 * @return {Promise<void>}
	 */
	reloadRelatedEntites(): Promise<void> {
		this._relatedEntities.next(null);
		return this._loadRelatedEntities(this.case.linkedRecord);
	}

	/**
	 * Request changes to approval with dialog, set case to 'in-progress'
	 *
	 * @return {Promise<void>}
	 */
	requestChangesApproval(): Promise<void> {
		return this._approvalService.requestChanges(this._approval.value);
	}

	/**
	 * Withdraws/deletes the approval
	 *
	 * @return {Promise<void>}
	 */
	withdrawApproval(): Promise<void> {
		return this._approvalService.withdraw(this._approval.value);
	}

	/**
	 * Resubmits the approval after requested changes
	 *
	 * @return {Promise<void>}
	 */
	resubmitApproval(): Promise<void> {
		return this._approvalService.resubmit(this._approval.value);
	}
	// -------------------------------------------------------------------------
	// @ Private methods
	// -------------------------------------------------------------------------
	/**
	 * Inits entity data
	 *
	 * @private
	 * @return {void}
	 */
	private _initEntity(): void {
		if (this.case && this.case.linkedRecord)
			this._entityHelperService
				.readEntity(this.case.linkedRecord)
				.pipe(
					takeUntil(this._unsubscribeAll),
					finalize(() => {
						this._entityObserver.unsubscribe();
					})
				)
				.subscribe((entityObserver) => {
					this._entityObserver = entityObserver;
					this._linkedEntity.next(entityObserver.data);
				});
	}

	/**
	 * Inits task data
	 *
	 * @private
	 * @return {void}
	 */
	private _initTasks(): void {
		if (this.case && this.case.id)
			this._caseService
				.readTasks(this.case.id)
				.pipe(
					takeUntil(this._unsubscribeAll),
					finalize(() => {
						this._taskObserver.unsubscribe();
					})
				)
				.subscribe((taskObserver) => {
					this._taskObserver = taskObserver;
					this._tasks.next(taskObserver.data);
				});
	}

	/**
	 * Inits file data for entity, case and related entities
	 *
	 * @private
	 * @param {Case} caseData
	 * @return {void}
	 */
	private async _initFiles(): Promise<void> {
		if (!this._case || !this._case.value) return;
		await this._loadRelatedEntities(this._case.value.linkedRecord);
		const entityRefs = this._relatedEntities.value?.map((relatedEntity) => this._entityHelperService.getEntityReference(relatedEntity.id)) ?? [];
		const caseRef = this._caseService.getCaseReference(this._case.value.id);
		combineLatest([this._storageService.readFileData(caseRef), this._storageService.readFileDataMultiple(entityRefs)])
			.pipe(
				takeUntil(this._unsubscribeAll),
				finalize(() => {
					this._fileObservable.unsubscribe();
				})
			)
			.subscribe(([caseFilesObserver, entityFilesData]) => {
				this._fileObservable = caseFilesObserver;
				const caseFiles = caseFilesObserver.data as FileData[];
				const linkedEntityFiles = entityFilesData.filter((elem) => elem.linkedRecord.id !== this._linkedEntity.value.id);
				const entityFiles = entityFilesData.filter((elem) => elem.linkedRecord.id === this._linkedEntity.value.id);
				const files = caseFiles.concat(linkedEntityFiles).concat(entityFiles);
				const fileMap: FileMap = {};
				fileMap[Collection.CASES] = caseFiles;
				fileMap[Collection.ENTITIES] = entityFiles;
				fileMap['Related entities'] = linkedEntityFiles;
				this._files.next(files);
				this._fileMap.next(fileMap);
			});
	}

	/**
	 * Loads the related entities
	 *
	 * @private
	 * @param {string} entityID
	 * @return {Promise<void>}
	 */
	private async _loadRelatedEntities(entityID: string): Promise<void> {
		const relatedEntities = await this._entityHelperService.getRelatedEntities(entityID);
		if (!relatedEntities) return;
		this._relatedEntities.next(relatedEntities);
	}

	/**
	 * Inits Approvals
	 *
	 * @private
	 * @return {void}
	 */
	private _initApproval(): void {
		combineLatest(this._approvalService.readApprovals())
			.pipe(
				takeUntil(this._unsubscribeAll),
				finalize(() => {
					this._approvalApproverObserver.unsubscribe();
					this._approvalOwnerObserver.unsubscribe();
				})
			)
			.subscribe(([approvalApproverObserver, approvalOwnerObserver]) => {
				this._approvalApproverObserver = approvalApproverObserver;
				this._approvalOwnerObserver = approvalOwnerObserver;
				const approvalData = [...approvalApproverObserver.data, ...approvalOwnerObserver.data]
					.filter((obj, index, self) => index === self.findIndex((o) => o.id === obj.id))
					.find((element) => element.linkedRecordPath.includes(this.case.id));
				this._approval.next(approvalData);
			});
	}

	// -------------------------------------------------------------------------
	// @ Accessors
	// -------------------------------------------------------------------------

	/**
	 * @type {Observable<Case>}
	 */
	get case$(): Observable<Case> {
		return this._case.asObservable();
	}

	/**
	 * @type {Case}
	 */
	get case(): Case {
		return this._case.value;
	}

	/**
	 * @type {(Observable<Contact | Company>)}
	 */
	get linkedEntity$(): Observable<Contact | Company> {
		return this._linkedEntity.asObservable();
	}

	/**
	 * @type {DocumentReference}
	 */
	get linkedRecordRef(): DocumentReference {
		return this._caseService.getCaseReference(this.case.id);
	}

	/**
	 * @type {Observable<SanctionsListCheck[]>}
	 */
	get sanctionListResults$(): Observable<SanctionsListCheck[]> {
		return this._sanctionListResults.asObservable();
	}

	/**
	 * @type {Observable<Alert[]>}
	 */
	get alerts$(): Observable<Alert[]> {
		return this._alerts.asObservable();
	}

	/**
	 * @type {Observable<Task[]>}
	 */
	get tasks$(): Observable<Task[]> {
		return this._tasks.asObservable();
	}

	/**
	 * @type {Observable<FileData[]>}
	 */
	get files$(): Observable<FileData[]> {
		return this._files.asObservable();
	}

	/**
	 * @type {Observable<FileMap>}
	 */
	get fileMap$(): Observable<FileMap> {
		return this._fileMap.asObservable();
	}

	/**
	 * @type {boolean}
	 */
	get isSetForApproval(): boolean {
		if (!this._case.value) return false;
		const a = this._case.value.stage;
		const b = [CaseStage.APPROVAL, CaseStage.APPROVED, CaseStage.DECLINED];
		return b.indexOf(a) !== -1;
	}

	/**
	 * @type {(Observable<(IContact | ICompany)[]>)}
	 */
	get relatedEntities$(): Observable<(IContact | ICompany)[]> {
		return this._relatedEntities.asObservable();
	}

	/**
	 * @type {Observable<Approval>}
	 */
	get approval$(): Observable<Approval> {
		return this._approval.asObservable();
	}
}
