import { DatePipe } from '@angular/common';
import { Component, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import dayjs from 'dayjs';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import { Timestamp } from 'firebase/firestore';
import { BehaviorSubject, Observable, Subject, combineLatest, finalize, takeUntil } from 'rxjs';

// types & enums
import { AuditAction } from 'app/shared/enums/audit-action';
import { Collection } from 'app/shared/enums/collection';
import { countries } from 'app/shared/enums/country-list';
import { Entity } from 'app/shared/enums/entity';
import { RunningProcessesSubType } from 'app/shared/enums/runningProcessesTypes';
import { AccountType } from 'app/shared/types/accountType';
import { Address } from 'app/shared/types/address';
import { AuditLog } from 'app/shared/types/audit-log';
import { Country } from 'app/shared/types/country';
import { NegativeNewsResults } from 'app/shared/types/negativeNewsResults';
import { SanctionListResults } from 'app/shared/types/sanctionListResults';
import { DataObserver } from 'app/shared/types/utilityTypes';

// service
import { TRANSLOCO_SCOPE, TranslocoService } from '@jsverse/transloco';
import { AuthService } from 'app/core/auth/auth.service';
import { DataService } from 'app/core/database/data.service';
import { PlatformInformationService } from 'app/core/platform-information/platform-information.service';
import { RiskCriteriaService } from 'app/modules/platform/risk-settings/risk-criteria/risk-criteria.service';
import { AuditLogService } from './audit-log.service';

// other
@Component({
	selector: 'audit-log',
	templateUrl: './audit-log.component.html',
	styles: ['.address-list {list-style-type: circle; padding-left:30px;}'],
	encapsulation: ViewEncapsulation.None,
	providers: [RiskCriteriaService, AuditLogService],
})
export class AuditLogComponent implements OnInit, OnDestroy, OnChanges {
	@Input() private record: any;
	private _dataObserver: DataObserver;
	private _unsubscribeAll: Subject<any> = new Subject<any>();
	private _auditLogs: BehaviorSubject<AuditLog[]> = new BehaviorSubject([]);
	private _translations = {};
	private _accountTypes: { [key: string]: AccountType } = {};

	constructor(
		private _authService: AuthService,
		private _dataService: DataService,
		private _platformInformationService: PlatformInformationService,
		@Inject(TRANSLOCO_SCOPE) private scope,
		private _translocoService: TranslocoService,
		private _datePipe: DatePipe,
		private _riskCriteriaService: RiskCriteriaService,
		private _auditLogService: AuditLogService
	) {}

	// -------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -------------------------------------------------------------------------

	ngOnInit(): void {
		// Get translated text
		this._translocoService.selectTranslateObject('auditLog', {}, this.scope).subscribe((results) => {
			this._translations = results;
		});

		combineLatest([this._riskCriteriaService.accountTypes$])
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(([accountTypes]) => {
				if (accountTypes) {
					this._accountTypes = accountTypes;
				}
			});
	}

	ngOnDestroy(): void {
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (this._dataObserver) this._dataObserver.unsubscribe();
		this._dataService
			.queryDataObservable(
				`${Collection.ACCOUNTS}/${this._authService.accountId}/${Collection.AUDITLOG}`,
				[{ field: 'parent', operator: '==', value: this.record.id }],
				[{ field: 'date', direction: 'desc' }]
			)
			.pipe(
				takeUntil(this._unsubscribeAll),
				finalize(() => this._dataObserver.unsubscribe())
			)
			.subscribe((dataObserver) => {
				this._dataObserver = dataObserver;
				this._auditLogs.next(dataObserver.data);
			});
	}

	// -------------------------------------------------------------------------
	// @ Public methods
	// -------------------------------------------------------------------------

	/**
	 * Returns whether the given dates are different days
	 * @param { string } current
	 * @param { string } compare
	 * @return { bolean }
	 */
	isSameDay(current: string, compare: string): boolean {
		return dayjs(current).isSame(dayjs(compare), 'day');
	}

	/**
	 * Get the relative format of the given date
	 * @param { string } date
	 * @return { string }
	 */
	getRelativeFormat(date: string): string {
		const today = dayjs().startOf('day');
		const yesterday = dayjs().subtract(1, 'day').startOf('day');
		// Is today?
		if (dayjs(date).isSame(today, 'day')) return this._translations['today'];
		// Is yesterday?
		if (dayjs(date).isSame(yesterday, 'day')) return this._translations['yesterday'];
		dayjs.extend(relativeTime);
		return dayjs(date).fromNow();
	}

	/**
	 * Track by function for ngFor loops
	 * @param { number } index
	 * @param { any } item
	 * @return { any }
	 */
	trackByFn(index: number, item: any): any {
		return item.id || index;
	}

	/**
	 * Get user name
	 * @param { string } userId
	 * @return { string }
	 */
	getUserName(userId: string): string {
		return this._platformInformationService.getUserNameById(userId);
	}

	/**
	 * Get description
	 * @param { AuditLog } log
	 * @return { string }
	 */
	getDescription(log: AuditLog): string {
		const textBlocks: string[] = [`<b>${this.getUserName(log.user)}</b>`];
		const action = log.subModule ? AuditAction.UPDATE : log.action;
		switch (action) {
			case AuditAction.CREATE:
				textBlocks.push(this._translations['created']);
				break;
			case AuditAction.UPDATE:
				textBlocks.push(this._translations['updated']);
				break;
			case AuditAction.DELETE:
				textBlocks.push(this._translations['deleted']);
				break;
			default:
				textBlocks.push('');
		}
		if (log.subModule && log.parentTitle && log.title) textBlocks.push(`${this._translations['modules'][log.subModule]} <b>${log.title}</b>`);
		else textBlocks.push(this._translations['thisRecord']);
		return textBlocks.join(' ');
	}

	/**
	 * Get extra content
	 * @param { AuditLog } log
	 * @return { string }
	 */
	getExtraContent(log: AuditLog): string {
		switch (log.action) {
			case AuditAction.UPDATE:
				if (log.subModule === Collection.RISKENGINE) return this._prepareChangeLogForSubmodules(log);
				return this._prepareChangeLog(log);
			case AuditAction.DELETE:
				return this._prepareChangeLogForDelete(log);
			case AuditAction.CREATE:
				return this._prepareChangeLogForCreate(log);
			default:
				return '';
		}
	}

	/**
	 * Get country by ISO
	 * @param { string } iso
	 * @return { Country }
	 */
	getCountryByIso(iso: string): Country {
		return countries.find((country) => country.iso === iso);
	}

	// -------------------------------------------------------------------------
	// @ Private methods
	// -------------------------------------------------------------------------

	/**
	 * Prepare change log for delete audit log
	 * @param { AuditLog } log
	 * @return { string[] }
	 */
	private _prepareChangeLogForDelete(log: AuditLog): string {
		const module = log.module;
		const subModule = log.subModule;
		const deleted = this._translations['deleted'];
		const moduleNames = this._translations['modules'];
		let prefix = '';
		let changeLog: string = log.title;
		if (subModule === Collection.FILES) prefix = moduleNames[subModule];
		if (module === Collection.ACCOUNTS && subModule) prefix = moduleNames[subModule];
		if (log.subModule === Collection.ENTITYRELATIONS)
			changeLog = `${this._translations['relBet']} ${log.changes.parentEntityName.newValue} ${this._translations['and']} ${log.changes.childEntityName.newValue}`;
		if (!changeLog) return '';
		return `${deleted} ${prefix} <b>${changeLog}</b>`;
	}

	/**
	 * Prepare change log for create audit log
	 * @param { AuditLog } log
	 * @return { string[] }
	 */
	private _prepareChangeLogForCreate(log: AuditLog): string {
		const changes = log.changes;
		const modules = this._translations['modules'];
		const created = this._translations['created'];
		const changeType = changes['type'] ? changes['type'].newValue : '';
		let changeLog = log.title;
		let preFix = '';
		if (log.subModule === Collection.FILES) preFix = modules[log.subModule];
		else if (log.module === Collection.ENTITIES) {
			if (log.subModule === Collection.ENTITYRELATIONS)
				changeLog = `${this._translations['relBet']} ${changes.parentEntityName.newValue} ${this._translations['and']} ${changes.childEntityName.newValue}`;
			if (changeType === Entity.COMPANY || changeType === Entity.CONTACT) preFix = changeType;
		} else if (log.subModule) preFix = modules[log.subModule];
		if (!changeLog && !preFix) return '';
		return `${created} ${preFix} <b>${changeLog}</b>`;
	}

	/**
	 * Prepare change log
	 * @param { AuditLog } log
	 * @return { string[] }
	 */
	private _prepareChangeLog(log: AuditLog): string {
		const changes = log.changes;
		const fieldNames = this._translations['fieldNames'];
		const updated = this._translations['updated'];
		const from = this._translations['from'];
		const to = this._translations['to'];
		const of = this._translations['of'];
		const file = this._translations['file'];
		const changeLog: string[] = Object.keys(changes).map((key) => {
			const oldVal: any = log.changes[key].oldValue;
			const newVal: any = log.changes[key].newValue;
			const fieldNameFormat = `<b>${fieldNames[key] || key}</b>`;
			let oldValFormat = this._getChangeValue(oldVal);
			let newValFormat = this._getChangeValue(newVal);
			if (log.subModule === Collection.TASKS) {
				oldValFormat = this._getTaskValue(oldVal, false, key);
				newValFormat = this._getTaskValue(newVal, true, key);
			}
			let logChange = [updated, fieldNameFormat];
			const fieldName = fieldNames[key] || key;
			if (fieldName === 'riskCriteria' || fieldName === 'riskLevels' || fieldName === 'accountTypes') {
				// Get updated log changes for updated change
				const updatedChanges = this._auditLogService.createLogChanges(oldVal, newVal, fieldNames[key] || key, this._accountTypes);
				logChange.push('<br>');
				// Add created logs to final logChange array
				for (const change of updatedChanges) {
					logChange = logChange.concat(change);
					logChange.push('<br>');
				}
				return logChange.join(' ');
			}
			if (!oldVal && !newVal) return logChange.join(' ');
			// Show file name for file update
			if (log.subModule === Collection.FILES) logChange.push(of, file, `<b>${log?.title}</b>`);
			if (oldVal) logChange.push(from, `<b>${oldValFormat}</b>`);
			logChange.push(to, `<b>${newValFormat}</b>`);
			return logChange.join(' ');
		});
		return changeLog.join('<br>');
	}

	/**
	 * Prepare change log for risk settings
	 * @param { AuditLog } log
	 * @return { string[] }
	 */
	private _prepareChangeLogForSubmodules(log: AuditLog): string {
		const modules = this._translations['modules'];
		const updated = this._translations['updated'];
		const changeLog = Object.keys(log.changes).map((key) => {
			if (modules[key]) return `${updated} <b>${modules[key]}</b>`;
			return '';
		});
		if (changeLog.length === 0) return '';
		return changeLog.join('<br>');
	}

	/**
	 * Get change value
	 * @param { any } value
	 * @return { string }
	 */
	private _getChangeValue(value: any): string {
		// Check for string value which has no value OR null
		if (typeof value === 'string' && !(value && value.length)) return this._translations['blank'];
		if (typeof value !== 'object') return value;
		if (this._instanceOfUndefined(value)) return this._translations['blank'];
		if (value instanceof Array) {
			if (!value.length) return '<ul class="address-list"><li>' + this._translations['blank'] + '</li></ul>';
			const valueArr = value.map((item) => this._getChangeValue(item));
			return '<ul class="address-list"><li>' + valueArr.join('</li><li>') + '</li></ul>';
		}
		if (value instanceof Timestamp) return this._datePipe.transform(value.toDate(), 'MMM dd, yyyy');
		if (this._instanceofAddress(value)) return this._formatAddress(value);
		if (this._instanceOfPhoneNumber(value))
			return `${value.label ? value.label + ' : ' : ''}${this.getCountryByIso(value.country).code} ${value.phoneNumber}`;
		if (this._instanceOfEmail(value)) return value.label ? `${value.label} : ${value.email}` : value.email;
		if (this._instanceOfNewsListResult(value)) return this._formatNewsResult(value);
		if (this._instanceOfSanctionListResult(value)) return this._formatSanctionListResult(value);
		return JSON.stringify(value);
	}

	/**
	 * Check if any object have value undefined
	 * @param { any } object
	 * @return { boolean }
	 */
	private _instanceOfUndefined(object: any): boolean {
		return object === null || 'undefined' in object;
	}

	/**
	 * Check if news list check result is exist or not
	 * @param { any } object
	 * @return { boolean }
	 */
	private _instanceOfNewsListResult(object: any): boolean {
		return 'averageScore' in object && 'date' in object && 'results' in object;
	}

	/**
	 * Check if sanction check list result is exist or not
	 * @param { any } object
	 * @return { boolean }
	 */
	private _instanceOfSanctionListResult(object: any): boolean {
		return Object.values(RunningProcessesSubType).some((elem) => Object.keys(object).includes(elem));
	}

	/**
	 * Check if address is exist or not
	 * @param { any } object
	 * @return { boolean }
	 */
	private _instanceofAddress(object: any): object is Address {
		return 'zip_code' in object && 'city' in object && 'line1' in object;
	}

	/**
	 * Check if phone number is exist or not
	 * @param { any } object
	 * @return { boolean }
	 */
	private _instanceOfPhoneNumber(object: any): boolean {
		return 'phoneNumber' in object;
	}

	/**
	 * Check if email is exist or not
	 * @param { any } object
	 * @return { boolean }
	 */
	private _instanceOfEmail(object: any): boolean {
		return 'email' in object;
	}

	/**
	 * Get formatted address
	 * @param { Address } address
	 * @return { string }
	 */
	private _formatAddress(address: Address): string {
		let addressData = [];
		if (address.line1) addressData.push(address.line1);
		if (address.line2) addressData.push(address.line2);
		if (address.city) addressData.push(`${address.zip_code} ${address.city}`);
		if (address.state) addressData.push(address.state);
		if (address.country) addressData.push(this.getCountryByIso(address.country).name);
		let addressStr = addressData.join(', ');
		if (address.label) addressStr = address.label + ' : ' + addressStr;
		return addressStr;
	}

	/**
	 * Get formatted Sanction list check result
	 * @param { SanctionListResults} sanctionListResults
	 * @return { string }
	 */
	private _formatSanctionListResult(sanctionListResults: { [key: string]: SanctionListResults }): string {
		const res = Object.values(sanctionListResults).map((vals) => {
			const dateAny: any = vals.date;
			const date = this._datePipe.transform(dateAny.toDate(), 'MMM dd, yyyy');
			const type = vals.type;
			const subType = vals.subType;
			const passResult = vals.sanctionListCheckPassed;
			const status = vals.sanctionListCheckStatus;
			const sanctionListResultData = `<ul class="address-list"><li>Type ${type} and Sub type ${subType} of Sanction check list result is updated on ${date}. Sanction check list result is ${passResult} and the status is ${status}. </li></ul>`;
			return sanctionListResultData;
		});
		return res.join('<br>');
	}

	/**
	 * Get formatted News check list result
	 * @param { NegativeNewsResults } newsListResult
	 * @return { string }
	 */
	private _formatNewsResult(newsListResult: NegativeNewsResults): string {
		const dateAny: any = newsListResult.date;
		const date = this._datePipe.transform(dateAny.toDate(), 'MMM dd, yyyy');
		const averageScore = newsListResult.averageScore;
		const newsListResultData = `<ul class="address-list"><li>News check list result is updated on ${date} and its avarage score is ${averageScore}. </li></ul>`;
		return newsListResultData;
	}

	private _getTaskValue(value: any, blank: boolean = false, fieldName?: string): string {
		if (fieldName === 'listId') {
			if (value.endsWith('todo')) return 'To do';
			if (value.endsWith('in-progress')) return 'In progress';
			if (value.endsWith('done')) return 'Done';
		}
		if (value instanceof Timestamp) return this._datePipe.transform(value.toDate(), 'MMM dd, yyyy');
		if (blank) return this._translations['blank'];
		if (value) return value;
		return '';
	}

	// -------------------------------------------------------------------------
	// @ Accessors
	// -------------------------------------------------------------------------

	/**
	 * @type {Observable<AuditLog[]>}
	 */
	get auditLogs$(): Observable<AuditLog[]> {
		return this._auditLogs.asObservable();
	}
}
