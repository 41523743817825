import { Inject, Injectable } from '@angular/core';
import { TRANSLOCO_SCOPE, TranslocoService } from '@jsverse/transloco';
import { Subject } from 'rxjs';

// enums & types
import { AccountType } from 'app/shared/types/accountType';
import { AuditLogChangesType, ObjectType } from 'app/shared/types/audit-log';

@Injectable()
export class AuditLogService {
	private _translations = {};
	private _unsubscribeAll: Subject<any> = new Subject<any>();

	constructor(
		private _translocoService: TranslocoService,
		@Inject(TRANSLOCO_SCOPE) private scope
	) {
		// Get translated text
		this._translocoService.selectTranslateObject('auditLog', {}, this.scope).subscribe((translations) => {
			this._translations = translations;
		});
	}

	// -------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -------------------------------------------------------------------------

	ngOnDestroy(): void {
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}

	/**
	 * Identify added items in two datasets
	 * @param { string[] } newKeys
	 * @param { string[] } oldKeys
	 * @param { AuditLogChangesType } newValue
	 * @return { Record<string, any> }
	 */
	private _findAddedKeys(newKeys: string[], oldKeys: string[], newValue: AuditLogChangesType): Record<string, any> {
		const added: Record<string, any> = {};
		newKeys.forEach((key) => {
			if (!oldKeys.includes(key)) {
				added[key] = newValue[key];
			}
		});
		return added;
	}

	/**
	 * Identify deleted items in two datasets
	 * @param { string[] } oldKeys
	 * @param { string[] } newKeys
	 * @param { AuditLogChangesType } oldValue
	 * @return { Record<string, any> }
	 */
	private _findDeletedKeys(oldKeys: string[], newKeys: string[], oldValue: AuditLogChangesType): Record<string, any> {
		const deleted: Record<string, any> = {};
		oldKeys.forEach((key) => {
			if (!newKeys.includes(key)) {
				deleted[key] = oldValue[key];
			}
		});
		return deleted;
	}

	/**
	 * Identify updated items in two datasets
	 * @param { string[] } oldKeys
	 * @param { string[] } newKeys
	 * @param { AuditLogChangesType } oldValue
	 * @param { AuditLogChangesType } newValue
	 * @return { Record<string, any> }
	 */
	private _findUpdatedKeys(
		oldKeys: string[],
		newKeys: string[],
		oldValue: AuditLogChangesType,
		newValue: AuditLogChangesType
	): Record<string, any> {
		const updated: Record<string, any> = {};
		newKeys.forEach((key) => {
			if (oldKeys.includes(key) && !this._deepEqual(oldValue[key], newValue[key])) {
				updated[key] = { oldValue: oldValue[key], newValue: newValue[key] };
			}
		});
		return updated;
	}

	/**
	 * Find changes for array-based datasets
	 * @param { AuditLogChangesType } oldValue
	 * @param { AuditLogChangesType } newValue
	 * @return { { added: ObjectType[]; deleted: ObjectType[]; updated: ObjectType[] } }
	 */
	private _findChangesForArray(
		oldValue: AuditLogChangesType,
		newValue: AuditLogChangesType
	): { added: ObjectType[]; deleted: ObjectType[]; updated: ObjectType[] } {
		const added = newValue.filter((newObj: ObjectType) => !oldValue.some((oldObj: ObjectType) => oldObj.id === newObj.id));
		const deleted = oldValue.filter((oldObj: ObjectType) => !newValue.some((newObj: ObjectType) => newObj.id === oldObj.id));
		const updated = newValue.filter((newObj: ObjectType) => {
			const oldObj = oldValue.find((oldObj: ObjectType) => oldObj.id === newObj.id);
			return oldObj && !this._deepEqual(oldObj, newObj);
		});

		return { added, deleted, updated };
	}

	/**
	 * Compare oldValue & newValue & return the changes like added, edited or deleted
	 * @param { AuditLogChangesType } oldValue
	 * @param { AuditLogChangesType } newValue
	 * @param { string } module
	 * @return { ObjectType }
	 */
	private findChanges(oldValue: AuditLogChangesType, newValue: AuditLogChangesType, module: string): ObjectType {
		const oldKeys = Object.keys(oldValue);
		const newKeys = Object.keys(newValue);

		if (['riskLevels', 'accountTypes'].includes(module)) {
			return {
				added: this._findAddedKeys(newKeys, oldKeys, newValue),
				deleted: this._findDeletedKeys(oldKeys, newKeys, oldValue),
				updated: this._findUpdatedKeys(oldKeys, newKeys, oldValue, newValue),
			};
		} else {
			return this._findChangesForArray(oldValue, newValue);
		}
	}

	/**
	 * Deep comparison for nested objects
	 * @param { ObjectType } obj1
	 * @param { ObjectType } obj2
	 * @return { boolean }
	 */
	private _deepEqual(obj1: ObjectType, obj2: ObjectType): boolean {
		if (obj1 === obj2) return true;

		if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
			return false;
		}

		const keys1 = Object.keys(obj1);
		const keys2 = Object.keys(obj2);

		if (keys1.length !== keys2.length) return false;

		for (const key of keys1) {
			if (!keys2.includes(key) || !this._deepEqual(obj1[key], obj2[key])) {
				return false;
			}
		}

		return true;
	}

	/**
	 * Add risk levels log
	 * @param {string[]} logChange
	 * @param {AuditLogChangesType} changes
	 * @param {{ [key: string]: AccountType }} accountTypes
	 * @return { void }
	 */
	private _addRiskLevelsLog(logChange: string[], changes: AuditLogChangesType, accountTypes: { [key: string]: AccountType }): void {
		Object.keys(changes).forEach((key) => {
			const accountTitle = accountTypes[key].title;
			logChange.push(
				`<br/>&#9679; Created risk Level for <b>${accountTitle}</b>. (Range: ${changes[key].intervalStart} - ${changes[key].intervalEnd})<br>`
			);
			changes[key].riskLevels.forEach((level: any) => {
				logChange.push(`&nbsp;&nbsp;&#8226; Title: <b>${level.title}</b> (Range: ${level.rangeStart} - ${level.rangeEnd})<br>`);
			});
		});
		return;
	}

	/**
	 * Add account types log
	 * @param {string[]} logChange
	 * @param {AuditLogChangesType} changes
	 * @return { void }
	 */
	private _addAccountTypesLog(logChange: string[], changes: AuditLogChangesType): void {
		Object.keys(changes).forEach((key) => {
			logChange.push(`<br/>&nbsp;&nbsp;&#8226; <b>${changes[key].title}</b>`);
		});
		return;
	}

	/**
	 * Track general changes and add to log
	 * @param { string[] } logChange
	 * @param { AuditLogChangesType } changes
	 * @return { void }
	 */
	private _addGeneralChanges(logChange: string[], changes: AuditLogChangesType): void {
		changes.forEach((item) => {
			logChange.push(`<b>${item.title}</b>`);
		});
		return;
	}

	/**
	 * Prepare log entry based on type
	 * @param {string} action
	 * @param {string} subType
	 * @param {string} extraText
	 * @return {string[]}
	 */
	private _initializeLog(action: string, subType: string, extraText = ''): string[] {
		const actionText = this._translations[action]; // Fetch action translation (created, deleted, updated)
		return [actionText, subType, extraText].filter(Boolean); // Remove empty values
	}

	/**
	 * Prepare the audit log for create operation in risk settings
	 * @param { AuditLogChangesType } changes
	 * @param { string } subType
	 * @param { { [key: string]: AccountType } } _accountTypes
	 * @return { string[] }
	 */
	private _prepareChangeLogForCreateRS(changes: AuditLogChangesType, subType: string, _accountTypes: { [key: string]: AccountType }): string[] {
		let logChange = this._initializeLog('created', subType);

		if (subType === 'riskLevels') {
			logChange.pop();
			logChange.push('for below account types:');
			this._addRiskLevelsLog(logChange, changes, _accountTypes);
		} else if (subType === 'accountTypes') {
			logChange.pop();
			logChange.push('<br>Below is the list of new account types:');
			this._addAccountTypesLog(logChange, changes);
		} else {
			this._addGeneralChanges(logChange, changes);
		}

		return logChange;
	}

	/**
	 * Prepare the audit log for delete operation in risk settings
	 * @param { AuditLogChangesType } changes
	 * @param { string } subType
	 * @param { { [key: string]: AccountType } } _accountTypes
	 * @return { string[] }
	 */
	private _prepareChangeLogForDeleteRS(changes: AuditLogChangesType, subType: string, _accountTypes: { [key: string]: AccountType }): string[] {
		const deleted = this._translations['deleted'];
		const forText = this._translations['for']; // Translation for 'for'
		let logChange = [deleted, subType, forText];

		// Delete audit for accountTypes and riskLevels
		if (subType === 'accountTypes' || subType === 'riskLevels') {
			Object.keys(changes).forEach((item: string) => logChange.push(item));
		} else {
			// Delete audit for riskCriteria
			changes.map((item: ObjectType) => logChange.push(`<br>&#9679; ${item.title}`));
		}
		logChange.push('<br>');
		return logChange;
	}

	/**
	 * Prepare the audit log for update operation in risk settings
	 * @param { AuditLogChangesType } changes
	 * @param { string } subType
	 * @param { AuditLogChangesType } oldVal
	 * @param { { [key: string]: AccountType } } _accountTypes
	 * @return { string[] }
	 */
	private _prepareChangeLogForUpdateRS(
		changes: AuditLogChangesType,
		subType: string,
		oldVal: AuditLogChangesType,
		_accountTypes: { [key: string]: AccountType }
	): string[] {
		const updated = this._translations['updated']; // Translation for 'updated'
		const forText = this._translations['for']; // Translation for 'for'

		let logChange = [updated, subType, forText, '<br/>'];

		if (subType === 'riskLevels') {
			Object.keys(changes).forEach((key: any) => {
				logChange.push(`&#9679; <b>${_accountTypes[key].title} :</b><br/>`);
				this._compareRiskLevels(changes[key].oldValue || [], changes[key].newValue || [], logChange);

				logChange.push('<br/>');
			});
		} else if (subType === 'accountTypes') {
			Object.keys(changes).forEach((key: any) => {
				logChange.push(`&#9679; <b>${_accountTypes[key] ? _accountTypes[key].title : key} :</b><br/>`);

				// Compare 2 simple objects which are oldValue & newValue
				this._trackDetailedChanges(changes[key].newValue, changes[key].oldValue, logChange, changes[key].newValue.title, _accountTypes);
				logChange.push('<br/>');
			});
		} else {
			// Iterate over the updated items and process changes
			changes.forEach((newItem: ObjectType) => {
				const oldItem = oldVal.find((item: ObjectType) => item.id === newItem.id);

				// Check for last value is : <br>, if yes then remove last 2 strings
				if (logChange.length > 0 && logChange[logChange.length - 1] === ': <br>') logChange.splice(-2, 2); // Removes the last element if it's ":<br>"

				if (!oldItem) {
					logChange.push(`${updated} - New item added: <b>${newItem.title}</b>`);
					return;
				}
				logChange.push(`&#9679; <b>${newItem.title}</b>`);
				logChange.push(': <br>');

				// Compare properties and track changes
				this._trackDetailedChanges(newItem, oldItem, logChange, newItem.title, _accountTypes);
			});
		}

		return logChange;
	}

	/**
	 * Convert the raw timestamp to milliseconds
	 * @param { any } rawTimestamp
	 * @return { number }
	 */
	private _getFormattedTimestamp(rawTimestamp: any): number {
		const { seconds, nanoseconds } = rawTimestamp;
		return seconds * 1000 + nanoseconds / 1e6; // Convert to milliseconds
	}

	/**
	 * Compare risk levels
	 * @param { ObjectType } oldLevels
	 * @param { ObjectType } newLevels
	 * @param { string[] } logChanges
	 * @return { void }
	 */
	private _compareRiskLevels(oldLevels: ObjectType, newLevels: ObjectType, logChanges: string[]): void {
		const from = this._translations['from']; // Translation for 'from'
		const to = this._translations['to']; // Translation for 'to'
		const deleted = this._translations['deleted']; // Translation for 'deleted'
		const created = this._translations['created']; // Translation for 'created'

		const maxLength = Math.max(oldLevels.riskLevels.length, newLevels.riskLevels.length);

		for (let i = 0; i < maxLength; i++) {
			const oldLevel = oldLevels.riskLevels[i];
			const newLevel = newLevels.riskLevels[i];

			if (!oldLevel && newLevel)
				logChanges.push(
					`&nbsp;&nbsp;&#8226; ${created} risk level: <b>${newLevel.title}</b> (Range: ${newLevel.rangeStart} - ${newLevel.rangeEnd})<br>`
				);
			else if (oldLevel && !newLevel)
				logChanges.push(
					`&nbsp;&nbsp;&#8226; ${deleted} risk level: <b>${oldLevel.title}</b> (Range: ${oldLevel.rangeStart} - ${oldLevel.rangeEnd})<br>`
				);
			else if (oldLevel && newLevel)
				Object.keys(newLevel).forEach((prop) => {
					if (newLevel[prop] !== oldLevel[prop]) {
						logChanges.push(
							`&nbsp;&nbsp;&#8226; Risk level <b>${newLevel.title || oldLevel.title}</b>: ${prop} changed ${from} <b>${oldLevel[prop]}</b> ${to} <b>${newLevel[prop]}</b><br>`
						);
					}
				});
		}
		return;
	}

	/**
	 * Add audit logs for array type changes
	 * @param { AuditLogChangesType } newVal
	 * @param { AuditLogChangesType } oldVal
	 * @param { string[] } logChange
	 * @param { string } context
	 * @param { { [key: string]: AccountType } } _accountTypes
	 */
	private _handleArrayChanges(
		newVal: AuditLogChangesType,
		oldVal: AuditLogChangesType,
		logChange: string[],
		context: string,
		_accountTypes: { [key: string]: AccountType }
	): void {
		const created = this._translations['created'];
		const deleted = this._translations['deleted'];
		const from = this._translations['from'];

		if (newVal && oldVal && newVal.length > oldVal.length) {
			newVal.forEach((child: ObjectType) => {
				if (!oldVal.find((item: ObjectType) => item.id === child.id)) {
					logChange.push(`&nbsp;&nbsp;&#8226; ${created} child to '${context}': <b>${child.title}</b> <br/>`);
				}
			});
		} else if (newVal && oldVal && oldVal.length > newVal.length) {
			oldVal.forEach((child: ObjectType) => {
				if (!newVal.find((item: ObjectType) => item.id === child.id)) {
					logChange.push(`&nbsp;&nbsp;&#8226; ${deleted} child ${from} '${context}': <b>${child.title}</b> <br/>`);
				}
			});
		} else if (newVal && oldVal && oldVal.length === newVal.length) {
			newVal.forEach((child: ObjectType) => {
				const oldChild = oldVal.find((item: ObjectType) => item.id === child.id);
				this._trackDetailedChanges(child, oldChild, logChange, `${context} > ${child.title || `Child with ID ${child.id}`}`, _accountTypes);
			});
		}
	}

	/**
	 * Track the iterative detailed changes
	 * @param { AuditLogChangesType } newItem
	 * @param { AuditLogChangesType } oldItem
	 * @param { string[] } logChange
	 * @param { string } context
	 * @param { { [key: string]: AccountType } } _accountTypes
	 * @return { void }
	 */
	private _trackDetailedChanges(
		newItem: AuditLogChangesType,
		oldItem: AuditLogChangesType,
		logChange: string[],
		context: string,
		_accountTypes: { [key: string]: AccountType }
	): void {
		const updated = this._translations['updated']; // Translation for 'updated'
		const from = this._translations['from']; // Translation for 'from'
		const to = this._translations['to']; // Translation for 'to'
		const of = this._translations['of']; // Translation for 'of'

		Object.keys(newItem).forEach((key) => {
			const oldVal = oldItem[key];
			const newVal = newItem[key];

			if (JSON.stringify(oldVal) === JSON.stringify(newVal)) return; // Skip unchanged values

			if (Array.isArray(newVal)) {
				this._handleArrayChanges(newVal, oldVal, logChange, context, _accountTypes);
			} else if (typeof newVal === 'object' && newVal !== null) {
				if (key === 'edited' || key === 'created') {
					const fromStr = oldVal && new Date(this._getFormattedTimestamp(oldVal)).toLocaleString();
					const toStr = newVal && new Date(this._getFormattedTimestamp(newVal)).toLocaleString();
					logChange.push(`&nbsp;&nbsp;&#8226; ${updated} '${key}' ${of} '${context}' ${from} "${fromStr}" ${to} "${toStr}" <br/>`);
				} else this._trackDetailedChanges(newVal, oldVal || {}, logChange, `${context} > ${key}`, _accountTypes);
			} else {
				if (oldVal !== undefined) {
					const words = context.split(' ');
					// Return the last word or an empty string if no words exist
					const lastWord = words.length > 0 ? words[words.length - 1] : '';

					const updatedKey = lastWord === 'weighting' ? _accountTypes[key].title : key;
					// Log primitive value changes
					logChange.push(`&nbsp;&nbsp;&#8226; ${updated} '${updatedKey}' ${of} '${context}' ${from} "${oldVal}" ${to} "${newVal}" <br/>`);
				}
			}
		});
		return;
	}

	/**
	 * Create actual audit log changes array
	 * @param { AuditLogChangesType } changes
	 * @param { string } action
	 * @param { string } subType
	 * @param { AuditLogChangesType } oldVal
	 * @param { { [key: string]: AccountType } } _accountTypes
	 * @return { string[] }
	 */
	private _getExtraContentForRiskSettings(
		changes: AuditLogChangesType,
		action: string,
		subType: string,
		oldVal: AuditLogChangesType,
		_accountTypes: { [key: string]: AccountType }
	): string[] {
		const isCreatelog = subType === 'riskLevels' || subType === 'accountTypes' ? Object.keys(changes).length : changes.length;
		switch (action) {
			case 'updated':
				return isCreatelog ? this._prepareChangeLogForUpdateRS(changes, subType, oldVal, _accountTypes) : [];
			case 'deleted':
				return isCreatelog ? this._prepareChangeLogForDeleteRS(changes, subType, _accountTypes) : [];
			case 'added':
				return isCreatelog ? this._prepareChangeLogForCreateRS(changes, subType, _accountTypes) : [];
			default:
				return [];
		}
	}

	/**
	 * Get the changes w.r.t oldValues & create the audit logs
	 * @param { AuditLogChangesType } oldVal
	 * @param { AuditLogChangesType } newVal
	 * @param { string } subType
	 * @param { { [key: string]: AccountType } } _accountTypes
	 * @return { string[] }
	 */
	createLogChanges(
		oldVal: AuditLogChangesType,
		newVal: AuditLogChangesType,
		subType: string,
		_accountTypes: { [key: string]: AccountType }
	): string[] {
		const changes = this.findChanges(oldVal, newVal, subType);
		const updateChangesForAllTasks: any = [];
		Object.keys(changes).forEach((action: string) => {
			const extraContentForRS = this._getExtraContentForRiskSettings(changes[action], action, subType, oldVal, _accountTypes);
			if (extraContentForRS.length) updateChangesForAllTasks.push(extraContentForRS);
		});
		return updateChangesForAllTasks;
	}
}
